import dynamic from 'next/dynamic';
import ReactMarkdown from 'react-markdown';
import 'react-markdown-editor-lite/lib/index.css';
import styled from 'styled-components';

import { Error, Label } from '../atoms';

const Container = styled.div`
  .rc-md-editor {
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: 16px;
    overflow: hidden;
    color: red;
  }
  .custom-html-style,
  textarea {
    color: #858585;
  }
`;

const MdEditor = dynamic(() => import('react-markdown-editor-lite'), {
  ssr: false,
});
const MarkDownInput = ({
  label,
  error,
  value,
  onChange,
  className,
  ...props
}) => (
  <Container className="mb-4">
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <MdEditor
      style={{ height: '250px' }}
      renderHTML={(val) => <ReactMarkdown>{val}</ReactMarkdown>}
      value={value}
      onChange={(data) => onChange(data.text)}
      {...props}
    />
    {error && <Error>{error}</Error>}
  </Container>
);

export default MarkDownInput;
