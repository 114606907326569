import { useEffect, useState } from 'react';
import { Error, Input, Label } from '../atoms';

const TextInput = ({
  name,
  label,
  error,
  className,
  textColor,
  borderRadius,
  labelClass,
  hasNotBorder,
  bgColor,
  onBlur,
  onChange,
  hasImageUploadInput,
  type,
  accept,
  borderColor,
  ...props
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
    return () => {
      setValue('');
    };
  }, [props.value]);

  return (
    <div className={`mb-4 ${labelClass}`}>
      {label && (
        <Label htmlFor={props.name} textColor={textColor}>
          {label}
        </Label>
      )}
      <Input
        name={name}
        type={type}
        accept={accept}
        className={className}
        borderRadius={borderRadius}
        hasNotBorder={hasNotBorder}
        bgColor={bgColor}
        borderColor={borderColor}
        value={hasImageUploadInput ? props.value : value}
        onChange={(e) => {
          if (hasImageUploadInput) {
            onChange(e);
          } else {
            setValue(e.target.value);
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          if (onChange && !hasImageUploadInput) {
            onChange({
              ...e,
            });
          }
        }}
        // {...props}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default TextInput;
