/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
// import { gql, useMutation } from '@apollo/client';
import { BsFillImageFill } from 'react-icons/bs';
import { CircleToBlockLoading } from 'react-loadingg';
import { BiPlus } from 'react-icons/bi';
import { Media, Player } from 'react-media-player';

import theme from '../../utils/theme';
import useFileUploading from '../../hooks/useFileUpload';
import { Label, Span, Error } from '../atoms';
import TextInputGroup from './TextInputGroup';

import ImageCropper from './ImageCropper';
import Avatar from './Avatar';

// const signFileUploadMutation = gql`
//   mutation signFileUpload($fileName: String!, $fileType: String!) {
//     signFileUpload(fileName: $fileName, fileType: $fileType) {
//       signedUrl
//       fileUrl
//     }
//   }
// `;

const UploadImageInput = ({
  value,
  onChange,
  imageOnly,
  disabled,
  error,
  onBlur,
  aspectRatio,
  isRound,
  label,
  toolTipText,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [image, setImage] = useState({});
  // const [signFileUpload] = useMutation(signFileUploadMutation);

  const [
    uploadedFileUrl,
    isUploading,
    fileType,
    { setFile, setFileName, setFileType, setUploadedFileUrl },
  ] = useFileUploading();

  const handleFileSelect = (inputData) => {
    const data = inputData.target.files[0];
    setFileName(data.name);
    setFileType(data.type);
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(data);

    reader.onload = function () {
      const theBlob = reader.result;
      if (data && data.type && data.type.includes('video')) {
        // eslint-disable-next-line no-buffer-constructor
        const blob = new Buffer(
          theBlob.replace(/^data:video\/\w+;base64,/, ''),
          'base64',
        );
        setFile(blob);
      } else {
        setImage(theBlob);
        setModalVisible(true);
      }
    };
  };

  useEffect(() => {
    if (uploadedFileUrl) {
      onChange(uploadedFileUrl);
    }
  }, [uploadedFileUrl]);

  useEffect(() => {
    setUploadedFileUrl(value);
  }, [value]);

  return (
    <>
      <div className="file-wrapper">
        <>
          <Label
            className={`${
              uploadedFileUrl || isRound ? 'p-0' : 'px-4 pt-10 pb-2'
            } ${
              !isRound && 'w-72 border border-primary bg-white flex-col'
            } inline-flex flex items-center rounded-xl cursor-pointer mb-0 overflow-hidden relative`}
          >
            {isRound && (
              <div className="rounded-full h-32 w-32 mx-auto border-2 p-1 relative">
                <Avatar
                  image={uploadedFileUrl}
                  className="h-full rounded-full"
                  alt="user-profile-image"
                />
                <div className="h-8 w-8 bg-white rounded-full shadow-2xl text-primary flex justify-center items-center absolute right-3 bottom-0">
                  <BiPlus />
                </div>
              </div>
            )}

            {!uploadedFileUrl && !isRound && (
              <>
                <BsFillImageFill className="h-20 w-20 text-primary inline-block" />
                <Span fontWeight="bold" size="base" className="mt-2 text-gray">
                  {label || 'Upload Image / Video'}
                </Span>
              </>
            )}
            {uploadedFileUrl && !isRound && (
              <>
                {uploadedFileUrl.includes('mp4') ||
                uploadedFileUrl.includes('mov') ||
                (fileType && fileType.includes('video')) ? (
                  <div className="w-full">
                    <Media>
                      <div className="media">
                        <div className="media-player relative">
                          <Player src={uploadedFileUrl} controls />
                        </div>
                      </div>
                    </Media>
                    <div
                      type="file"
                      accept={
                        imageOnly
                          ? 'image/png, image/jpeg, image/jpg'
                          : 'image/png, image/jpeg, image/jpg, video/mp4, video/mov, video/quicktime'
                      }
                      name="image"
                      onChange={(val) => handleFileSelect(val)}
                      className="bg-primary shadow-xl rounded-lg  border border-transparent hover:border-transparent px-6 transition duration-400 focus:outline-none  font-bold text-black"
                      isActive
                    >
                      UPLOAD NEW VIDEO
                    </div>
                  </div>
                ) : (
                  <img
                    src={uploadedFileUrl}
                    className="h-48 w-full rounded-xl"
                    alt="thumbnail"
                  />
                )}
              </>
            )}

            {isUploading && (
              <div
                className={`${
                  isRound && 'rounded-full'
                } absolute top-0 left-0 right-0 bottom-0 bg-black opacity-80`}
              >
                <CircleToBlockLoading color={theme.primary} />
              </div>
            )}
            <div className={uploadedFileUrl && '-mb-4'}>
              <TextInputGroup
                hasImageUploadInput
                disabled={isUploading || disabled}
                type="file"
                accept={
                  imageOnly
                    ? 'image/png, image/jpeg, image/jpg'
                    : 'image/png, image/jpeg, image/jpg, video/mp4, video/mov, video/quicktime'
                }
                name="image"
                onChange={(val) => handleFileSelect(val)}
                onBlur={onBlur}
                className="hidden"
              />
            </div>
          </Label>
          {toolTipText && (
            <p className="mt-0 text-gray text-sm">
              <strong>*</strong> {toolTipText} <strong>*</strong>
            </p>
          )}
          {error && <Error className="mt-0">{error}</Error>}
        </>
      </div>

      {image && modalVisible && (
        <ImageCropper
          aspectRatio={aspectRatio}
          modalVisible={modalVisible}
          src={image}
          onChange={async (croppedUrl) => {
            // setCroppedFile(croppedUrl);
            setModalVisible(false);
            setFile(croppedUrl);
          }}
          handleClose={() => setModalVisible(false)}
        />
      )}
    </>
  );
};

export default UploadImageInput;
