import { Error, TextArea, Label } from '../atoms';

const TextAreaGroup = ({
  label,
  error,
  className,
  textColor,
  borderRadius,
  labelClass,
  name,
  ...props
}) => (
  <div className={`mb-3 ${labelClass}`}>
    {label && (
      <Label htmlFor={props.name} textColor={textColor}>
        {label}
      </Label>
    )}
    <TextArea
      name={name}
      className={className}
      borderRadius={borderRadius}
      {...props}
    />
    {error && <Error>{error}</Error>}
  </div>
);

export default TextAreaGroup;
