import Cleave from 'cleave.js/react';
import { Error, Label } from '../atoms';

const CardInput = ({ label, error, className, ...props }) => (
  <div className="mb-4">
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <Cleave
      className={`w-full h-auto bg-white rounded-2xl border border-primary focus:border-primary focus:bg-white focus:border-primary text-base outline-none text-gray py-5 px-8 resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
      {...props}
    />
    {error && <Error>{error}</Error>}
  </div>
);

export default CardInput;
