import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiChevronDown } from 'react-icons/fi';
import Avatar from './Avatar';

const Dropdown = ({
  options,
  image,
  alt,
  isSecondary,
  menuText,
  hideDropDownIcon,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`${
            isSecondary ? 'lg:py-5 py-3 lg:px-8 px-2 text-dark' : 'text-gray'
          } items-center inline-flex justify-center w-full text-base font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          {menuText}{' '}
          {image && (
            <span className="ml-3 h-10 w-10 border-2 p-0.5 rounded-full">
              <Avatar className="rounded-full" image={image} alt={alt} />
            </span>
          )}
          {!hideDropDownIcon && <FiChevronDown className="h-6 w-6 ml-3" />}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            isSecondary ? 'top-16' : 'origin-top-right'
          } absolute border border-gray-100 overflow-hidden right-0 z-40 mt-2 bg-white divide-y divide-gray-100 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {options.map((option) => {
            if (!option.isHidden) {
              return (
                <Menu.Item key={option.id} onClick={option.onClick}>
                  {() => (
                    <button
                      disabled={option.disabled}
                      type="button"
                      className={`${
                        option.disabled
                          ? 'bg-backgroundDark bg-opacity-25 text-black cursor-not-allowed'
                          : 'text-gray hover:bg-primary'
                      } w-full min-w-max py-2 text-sm focus:outline-none px-3`}
                    >
                      {option.title}
                    </button>
                  )}
                </Menu.Item>
              );
            }
            return null;
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
