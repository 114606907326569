const Avatar = ({ className, image, alt, ...props }) => {
  return (
    <img
      className={`${className}`}
      src={image || '/images/avatar.png'}
      alt={alt}
      {...props}
    />
  );
};

export default Avatar;
