const ProgressBar = ({ width, backgroundColor, progressColor }) => (
  <div className="relative pt-1">
    <div
      className={`overflow-hidden h-2 mb-4 text-xs flex rounded bg-${backgroundColor}`}
    >
      <div
        style={{ width: `${width}` }}
        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${progressColor}`}
      />
    </div>
  </div>
);

export default ProgressBar;
