// import Datetime from 'react-datetime';
import styled from 'styled-components';
import moment from 'moment';

import 'react-datetime/css/react-datetime.css';
import { Error, Label } from '../atoms';

const Container = styled.div`
  input {
    border-radius: 20px;
    padding: 1.25rem 2rem;
    width: 100%;
    :focus,
    :focus-visible,
    :active {
      outline: none;
      border: none;
    }
  }
`;
const DatePickerInput = ({
  label,
  error,
  className,
  value,
  onChange,
  ...props
}) => {
  // const yesterday = moment().subtract(1, 'day');
  // const valid = (current) => {
  //   return current.isAfter(yesterday);
  // };

  return (
    <Container className="mb-4">
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <input
        type="datetime-local"
        name="dayTime"
        value={moment(value).format('YYYY-MM-DDTHH:mm')}
        onChange={(e) => onChange(moment(e.currentTarget.value).toISOString())}
        className={`w-full h-auto bg-white rounded-2xl border border-primary focus:border-primary focus:bg-white focus:border-primary text-base outline-none text-gray  resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
      />

      {/* <Datetime
        isValidDate={valid}
        inputProps={{
          value: value ? moment(value).format('DD-MMM-YYYY hh:mm A') : '',
        }}
        // dateFormat="DD/MMM/YYYY"
        value={moment(value)}
        initialValue={moment(value)}
        onChange={(date) => onChange(moment(date).toISOString())}
        className={`w-full h-auto bg-white rounded-2xl border border-primary focus:border-primary focus:bg-white focus:border-primary text-base outline-none text-gray  resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
        {...props}
      /> */}
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default DatePickerInput;
