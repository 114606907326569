import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';

const Container = styled.div`
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: ${(props) => props.theme.primary} !important;
    color: ${(props) => props.theme.secondary} !important;
  }
  .DayPicker-Day {
    padding: 0 !important;
    height: 2rem;
    width: 2rem;
  }
`;
const Calender = ({ selectedDays }) => {
  return (
    <Container className="shadow-lg bg-backgroundLite px-6 py-14 rounded-xl w-max mx-auto xl:w-full text-center">
      <DayPicker selectedDays={selectedDays} disabled />
    </Container>
  );
};

export default Calender;
