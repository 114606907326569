import { Switch } from '@headlessui/react';

const ToggleSwitch = ({ checked, disabled, onChange }) => {
  return (
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={`${
        checked ? 'bg-primary' : 'bg-backgroundDark'
      } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
    >
      <span
        className={`${
          checked ? 'translate-x-6' : 'translate-x-1'
        } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
      />
    </Switch>
  );
};

export default ToggleSwitch;
