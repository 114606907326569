import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { Error, Label } from '../atoms';

const SelectInput = styled.div`
  .css-2b097c-container {
    font-size: 1rem;
    box-shadow: none;
  }
  .css-yk16xz-control {
    border-radius: 0 !important;
    border-width: 0;
    padding: 0.85rem 1.25rem !important;
    box-shadow: none;
    background-color: transparent;
  }
  .css-1pahdxg-control {
    border-radius: 1rem !important;
    border-width: 0;
    padding: 0.85rem 1.25rem !important;
    box-shadow: none;
    border-radius: 1rem;
  }
  .css-1uccc91-singleValue {
    color: #858585;
    font-size: ${(props) => (props.isSmall ? '0.75rem' : '1rem')} !important;
  }
  .css-1rhbuit-multiValue {
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 30px;
  }

  .css-tlfecz-indicatorContainer {
    color: ${(props) => props.theme.primary};
  }
  .css-1okebmr-indicatorSeparator {
    background: ${(props) => props.theme.primary};
  }
`;

const MultiSelect = ({
  error,
  options,
  isSmall,
  textAlign,
  label,
  ...props
}) => {
  const CustomStyle = {
    menu: (provided) => ({
      ...provided,
      fontSize: isSmall ? '0.75rem' : '1rem',
    }),

    option: (base, state) => {
      let backgroundColor = 'white';
      let color = '';
      if (state.isSelected) {
        backgroundColor = '#00FF8B';
        color = '#000000';
      }
      if (state.isFocused) {
        backgroundColor = '#00FF8B70';
      }
      return {
        ...base,
        backgroundColor,
        color,
        textAlign: textAlign || 'center',
      };
    },
  };
  return (
    <SelectInput className="mb-4 w-full" isSmall={isSmall}>
      {label && <Label>{label}</Label>}
      <Select
        instanceId="postType"
        isClearable
        styles={CustomStyle}
        options={options}
        className="bg-white rounded-2xl border border-primary focus:border-primary focus:bg-white focus:border-primary text-base outline-none text-gray"
        {...props}
      />
      {error && <Error>{error}</Error>}
    </SelectInput>
  );
};
export default MultiSelect;
