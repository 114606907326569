import { FieldArray } from 'formik';
import { useState } from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { GoPrimitiveDot } from 'react-icons/go';

import TextInputGroup from './TextInputGroup';
import { Button, Span } from '../atoms';

const CreateListInput = ({ label, name, error, items }) => {
  const [value, setValue] = useState('');
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <div className="flex justify-center items-center">
            <div className="relative w-full">
              <TextInputGroup
                placeholder={`Enter ${name} here`}
                name={name}
                label={label}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                error={error}
              />
              <div className="absolute top-11 right-2">
                <Button
                  isActive
                  size="medium"
                  className="pl-5 pr-5 mx-0.5 my-1"
                  onClick={async () => {
                    if (value) {
                      await arrayHelpers.push(value);
                      setValue('');
                    }
                  }}
                >
                  +
                </Button>
              </div>
            </div>
          </div>
          {items &&
            items.map((item, index) => {
              const keyValue = index + 1;
              return (
                <div
                  key={keyValue}
                  className="flex justify-between items-center mb-1"
                >
                  <div className="w-full flex items-center">
                    <GoPrimitiveDot className="h-4 w-4 text-primary" />{' '}
                    <Span className="text-gray " size="base">
                      {item}
                    </Span>
                  </div>
                  <div
                    className="ml-4 px-0"
                    type="button"
                    onClick={() => arrayHelpers.remove(index)} // remove a feature from the list
                  >
                    <RiDeleteBin6Fill className="h-4 w-4 text-altText" />
                  </div>
                </div>
              );
            })}
        </>
      )}
    />
  );
};

export default CreateListInput;
