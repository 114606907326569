import PropTypes from 'prop-types';
import { AiFillWarning } from 'react-icons/ai';

const EmptyState = ({ children, className, classNameChild }) => (
  <div className={`flex justify-center mt-48 text-center ${className}`}>
    <AiFillWarning className="h-10 w-10 text-yellow-300" />
    <div className={`self-center text-xl pl-2 ${classNameChild}`}>
      {children}
    </div>
  </div>
);

EmptyState.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  classNameChild: PropTypes.string,
};

EmptyState.defaultProps = {
  children: 'No users available!',
  className: '',
  classNameChild: '',
};

export default EmptyState;
